<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Color</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-col cols="12">
                  <ImageComp
                    :singleImage="editing.colorCombination"
                    @stepper="winStepper"
                    :componentType="'mainImage'"
                    :heading="'Upload Image'"
                  />
                </v-col>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editdialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="editColor(editing)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Color Combination
            <v-spacer></v-spacer>
            <!-- <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->
            <v-layout justify-end class="px-5">
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="800px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    <span>Add Color Combination</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addcat" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Color Combination</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <ImageComp
                              :singleImage="mainImagePreview"
                              @stepper="winStepper"
                              :heading="'Upload Image'"
                              :componentType="'mainImage'"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addcat"
                        @click="colorAdd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-layout>
          </v-card-title>
          <v-layout wrap justify-start py-2>
            <v-flex xs12 sm6 md3 pa-2 v-for="(item, i) in user" :key="i">
              <v-img
                width="auto"
                height="200px"
                :src="baseURL + item.colorCombination"
              >
                <v-layout wrap justify-end>
                  <v-flex xs1 text-right>
                    <v-icon
                      style="cursor: pointer; z-index: 9999"
                      @click="editCategoryDialog(item)"
                      color="red"
                      >mdi-pencil</v-icon
                    >
                  </v-flex>
                  <v-flex xs1 text-right>
                    <v-badge
                      offset-x="20"
                      offset-y="2"
                      small
                      overlap
                      avatar
                      color="#FF1313"
                    >
                      <template v-slot:badge>
                        <v-dialog v-model="item.dialogDelete" max-width="500px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar style="cursor: pointer; z-index: 9999">
                              <v-icon small v-on="on" v-bind="attrs"
                                >mdi-close</v-icon
                              >
                            </v-avatar>
                          </template>
                          <v-card>
                            <v-card-title
                              style="
                                font-family: poppinsregular;
                                font-size: 15px;
                                color: #000;
                              "
                              >Are you sure you want to delete this
                              Image?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                small
                                @click="item.dialogDelete = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                small
                                @click="remove(item)"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </template>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FF4343"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-badge>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-flex>
          </v-layout>
          <!-- </v-flex>
            </v-layout>
          </v-flex> -->
        </v-card>
      </v-flex>
    </v-layout>
    <div class="pt-2" v-if="pages > 1">
      <v-pagination
        :length="pages"
        :total-visible="7"
        v-model="currentPage"
        color="#FF0000"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      editing: "",
      editId: "",
      appLoading: false,
      search: "",
      msg: "",
      file: null,
      file1: null,
      coverFile: null,
      mainImagePreview: "",
      productImagePreview: "",
      logoImagePreview: "",
      image: "",
      mainImage: "",
      logoImage: "",
      productImage: "",
      showsnackbar: false,
      ServerError: false,
      shopDialog: false,
      dialog: false,
      addcat: false,
      editingitem: "",
      editdialog: false,
      currentPage: 1,
      pages: 0,
      count: 20,
      totalData: 0,
      totalRows: 0,
      user: [],
      selectedTags: [],
      Rules: [(v) => !!v || "Required"],
      curProduct: null,
      curProductEdit: null,
      imgId: "",
      shopDialogEdit: false,
      edit: null,
      editproduct: null,
      keyword: "",
      dialogDelete: false,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "mainImage") {
        this.file = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/shopbylook/colorcombination/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editCategoryDialog(item) {
      console.log("content=", item);
      this.edit = true;
      this.editing = item;
      this.editdialog = true;
      this.storyCategory = item.storyCategory;
      this.editId = item._id;
    },
    remove(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/shopbylook/colorcombination/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.dialogDelete = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    colorAdd() {
      this.addDialog = false;
      let formData = new FormData();
      formData.append("photo", this.file);
      axios({
        url: "/shopbylook/discover/ColorCombination",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Added Successfully!";
            this.showsnackbar = true;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editColor(id) {
      this.addDialog = false;
      let formData = new FormData();
      formData.append("photo", this.file);
      formData.append("id", id._id);
      axios({
        url: "/shopbylook/discover/ColorCombination",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Successfully!";
            this.showsnackbar = true;
            this.editdialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    rowClick(item) {
      this.$router.push("/productDetails/" + item._id);
    },
  },
};
</script>
<style scoped>
.column {
  column-count: 4;
  column-gap: 1em;
}
.row {
  margin: 0 0 1em !important;
  width: 100% !important;
  display: inline-block !important;
}
.mainbody {
  position: absolute;
  top: 0; /* Header Height */
  bottom: 0; /* Footer Height */
  width: 100%;
}
</style>
